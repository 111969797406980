import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { Box, Grid, Paper, styled, Theme, useTheme } from '@mui/material'
import caremarkOutlineBranding from '@/img/logo-black-trademark.svg'

import useMediaQuery from '@mui/material/useMediaQuery'

import backgroundPlaceholder from '@/img/background/password-reset-background.png?width=40&format=jpeg&imagetools'
import background from '@/img/background/password-reset-background.png?format=jpeg&imagetools'
import backgroundSrcSet from '@/img/background/password-reset-background.png?w=500;900;1200&format=jpeg&srcset'
import backgroundWebPSrcSet from '@/img/background/password-reset-background.png?format=webp&srcset'
import CssBaseline from '@mui/material/CssBaseline'

const BackgroundImage = styled('img')({
    pointerEvents: 'none',
    position: 'absolute',
    objectFit: 'cover',
    minWidth: '100%',
    maxWidth: '100%',
    minHeight: '100%',
    maxHeight: '100%',
    boxShadow: '#FFFFFF29 5px 5px 2px 100% inset',
})

const BackgroundBlur = styled(BackgroundImage)({
    transform: 'scale(1.1)',
    transition: 'opacity .5s ease-in',
    filter: 'blur(20px)',
})

const MainGrid = styled(Grid)(({ theme }) => ({
    minWidth: '95%',
    [theme.breakpoints.up('sm')]: {
        minWidth: 400,
        maxWidth: 400,
    },
}))

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    textAlign: 'left',
    maxWidth: '97%',
    boxSizing: 'border-box',
    [theme.breakpoints.down('md')]: {
        margin: '0px 5px',
    },
    zIndex: 100,
}))

export const MidmarkV4Layout = () => {
    const [backgroundLoaded, setBackgroundLoaded] = useState(false)
    const theme = useTheme()
    const isSmallScreen = useMediaQuery<Theme>(theme.breakpoints.down('sm'), {
        defaultMatches: true,
    })

    return (
        <>
            <CssBaseline />
            <Box
                sx={{
                    overflow: 'hidden',
                    position: 'relative',
                    width: '100%',
                }}
            >
                <picture>
                    <source data-testid="background-source" srcSet={backgroundWebPSrcSet} type="image/webp" />
                    <BackgroundImage
                        data-testid="background-image"
                        onLoad={() => setBackgroundLoaded(true)}
                        srcSet={backgroundSrcSet}
                        src={background.src}
                        alt=""
                    />
                </picture>
                <Box
                    sx={{
                        backgroundColor: 'rgba(61, 61, 61, 0.3)',
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                    }}
                />
                <BackgroundBlur
                    data-testid="background-image-blur"
                    style={{ opacity: backgroundLoaded ? 0 : 1 }}
                    src={backgroundPlaceholder.src}
                    alt=""
                />
                <Grid
                    sx={{
                        flexGrow: 1,
                        height: '100vh',
                    }}
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <MainGrid item>
                        <Grid
                            item
                            xl={10}
                            xs={12}
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="stretch"
                            style={{ maxWidth: '100%' }}
                        >
                            <StyledPaper
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'nowrap',
                                    flexDirection: 'column',
                                }}
                                elevation={isSmallScreen ? 0 : 1}
                                role="main"
                            >
                                <Grid item sx={{ mb: 2 }}>
                                    <img
                                        src={caremarkOutlineBranding}
                                        width="112"
                                        aria-hidden="true"
                                    />
                                </Grid>
                                <Outlet />
                            </StyledPaper>
                        </Grid>
                    </MainGrid>
                </Grid>
            </Box>
        </>
    )
}
